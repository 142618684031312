class Emails {
    private lv: LifeVine;
    private page: JQuery;
    private listView: JQuery;
    private editorView: JQuery;
    private form: JQuery;

    private email: EmailsEmail;
    private original: EmailsOriginal;

    private emails: EmailsEmail[] = [
        {
            name: 'Welcome',
            subject: 'email_welcome_subject',
            message: 'email_welcome_message',
            mocks: []
        },
        {
            name: 'Account Confirmation',
            subject: 'email_confirmation_subject',
            message: 'email_confirmation_message',
            mocks: ['booking', 'client', 'dependents', 'location', 'sitter']
        },
        {
            name: 'New Booking',
            subject: 'email_new_booking_subject',
            message: 'email_new_booking_message',
            mocks: ['booking', 'client', 'dependents', 'location', 'sitters']
        },
        {
            name: 'New Booking Available',
            subject: 'email_new_booking_available_subject',
            message: 'email_new_booking_available_message',
            mocks: ['booking', 'client', 'dependents', 'location', 'sitters']
        },
        {
            name: 'Sitter Assigned',
            subject: 'email_sitter_assigned_subject',
            message: 'email_sitter_assigned_message',
            mocks: ['booking', 'client', 'dependents', 'location', 'sitter']
        },
        {
            name: 'Sitter Declined',
            subject: 'email_sitter_declined_subject',
            message: 'email_sitter_declined_message',
            mocks: ['booking', 'client', 'dependents', 'location', 'sitter']
        },
        {
            name: 'Client Cancelled',
            subject: 'email_client_cancelled_subject',
            message: 'email_client_cancelled_message',
            mocks: ['booking', 'client', 'dependents', 'location', 'sitter']
        },
        {
            name: 'Sitter Cancelled',
            subject: 'email_sitter_cancelled_subject',
            message: 'email_sitter_cancelled_message',
            mocks: ['booking', 'client', 'dependents', 'location', 'sitter']
        },
        {
            name: 'Booking Complete',
            subject: 'email_booking_complete_subject',
            message: 'email_booking_complete_message',
            mocks: ['booking', 'client', 'dependents', 'location', 'sitter']
        }
    ];

    private mocks: EmailsMockData = {
        admin: {
            first_name: 'Alice',
            last_name: 'Admin',
            email: 'alice@wonderland.com',
            isClient: false,
            isSitter: false,
            isAdmin: true
        },
        booking: {
            start_time: '2017-03-28 18:30:00',
            end_time: '2017-03-29 00:45:00',
            duration_h: '6.25',
            dependents_num: '2',
            price_estimate: '350',
            first_available: true,
            notes: 'Some regular notes.'
        },
        client: {
            first_name: 'Chloe',
            last_name: 'Client',
            email: 'chloe@chloeshoes.com',
            isClient: true,
            isSitter: false,
            isAdmin: false
        },
        dependents: [
            {
                first_name: 'First',
                last_name: 'Dependent',
                age: '6'
            },
            {
                first_name: 'Second',
                last_name: 'Dependent',
                age: '16mo'
            }
        ],
        location: {
            line1: '123 First Street',
            line2: 'Apt 29',
            city: 'New York',
            state: 'NY',
            zip: '45678'
        },
        sitter: {
            first_name: 'Sam',
            last_name: 'Sitter',
            email: 'sam@samthesitter.com',
            isClient: false,
            isSitter: true,
            isAdmin: false
        },
        sitters: [
            {
                first_name: 'Sam',
                last_name: 'Sitter',
                email: 'sam@samthesitter.com'
            },
            {
                first_name: 'Steve',
                last_name: 'Sitter',
                email: 'steve@stevethesitter.com'
            }
        ]
    };

    private templateHelp = {
        booking: {
            header: 'Booking',
            example: '{{booking.dependents_num}}<br>{{DTFormat booking.start_time \'M/d/Y\'}}',
            fields: [
                'start_time',
                'end_time',
                'duration_h',
                'dependents_num',
                'price_estimate',
                'first_available',
                'notes',
                'created_at'
            ]
        },
        client: {
            header: 'Client',
            example: '{{FullName client}}<br>{{client.first_name}}',
            fields: [
                'first_name',
                'last_name',
                'email'
            ]
        },
        dependents: {
            example: '{{#each dependents}}<br>{{first_name}}<br>{{FullName this}}<br>{{/each}}',
            header: 'Dependents',
            fields: [
                'first_name',
                'last_name',
                'age'
            ]
        },
        location: {
            header: 'Location',
            example: '{{Address sitter}}<br>{{sitter.first_name}}',
            fields: [
                'line1',
                'line2',
                'city',
                'state',
                'zip'
            ]
        },
        sitter: {
            header: 'Sitter',
            example: '{{FullName sitter}}<br>{{sitter.first_name}}',
            fields: [
                'first_name',
                'last_name'
            ]
        },
        sitters: {
            header: 'Sitters',
            example: '{{#each sitters}}<br>{{first_name}}<br>{{FullName this}}<br>{{/each}}',
            fields: [
                'first_name',
                'last_name'
            ]
        },
        to: {
            header: 'Recipient',
            example: '{{FullName to}}<br>{{to.first_name}}',
            fields: [
                'first_name',
                'last_name',
                'email',
                'isClient',
                'isSitter',
                'isAdmin'
            ]
        },
        user: {
            header: 'User',
            example: '{{FullName user}}<br>{{user.first_name}}',
            fields: [
                'first_name',
                'last_name',
                'email',
                'user_type'
            ]
        },
    };

    constructor(lv: LifeVine) {
        this.lv = lv;
        this.page = jQuery('#page-emails');
        this.listView = this.page.find('.emails-list').hide();
        this.editorView = this.page.find('.emails-editor').hide();
        this.form = this.editorView.find('form');
        this.renderList();

        this.editorView.on('click', '.preview-button', event => this.preview(event));
        this.form.on('reset', () => history.back());
        this.form.on('submit', event => this.save(event));
    }

    public index() {
        this.editorView.slideUp(500);
        this.listView.slideDown(500);
    }

    public edit(idx) {
        this.email = this.emails[idx];
        if (!this.email) {
            Materialize.toast('Invalid email reference', 2000);
            history.back();
        }

        this.loadMessage();
        this.loadSubject();

        this.original = {
            subject: '',
            subjectId: 0,
            message: '',
            messageId: 0
        };

        this.renderTemplateHelp();

        this.listView.slideUp(500);
        this.editorView.slideDown(500);
    }

    private loadSubject() {
        this.lv.settings().find(this.email.subject)
            .done(subject => {
                this.original.subject = subject.value;
                this.original.subjectId = subject.id;
                setFormData(this.form, this.original);
                Materialize.updateTextFields();
                this.form.find('textarea').trigger('autoresize');
            });
    }

    private loadMessage() {
        this.lv.settings().find(this.email.message)
            .done(message => {
                this.original.message = message.value;
                this.original.messageId = message.id;
                setFormData(this.form, this.original);
                Materialize.updateTextFields();
                this.form.find('textarea').trigger('autoresize');
            });
    }

    private save(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        let data = getFormData(this.form);

        if (data.subject !== this.original.subject) {
            this.lv.settings().save(this.original.subjectId, data.subject);
        }
        if (data.message !== this.original.message) {
            this.lv.settings().save(this.original.messageId, data.message);
        }
        history.back();
    }

    private preview(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        let type = jQuery(event.target).attr('data-type');

        try {
            let formData = getFormData(this.form);
            let bodyTemplate = Handlebars.compile(formData.message);
            let subjectTemplate = Handlebars.compile(formData.subject);

            let data: EmailsMockData = {
                to: this.mocks[type]
            };
            this.email.mocks.forEach(name => {
                data[name] = this.mocks[name];
            });

            let previewData = {
                to: this.mocks[type],
                subject: subjectTemplate(data),
                body: bodyTemplate(data)
            };

            let template = Handlebars.compile(jQuery('#emails-preview-template').html());
            this.editorView.find('.emails-preview').html(template(previewData));
        } catch (e) {
            Materialize.toast('Could not compile template', 2000);
        }
    }

    private renderList() {
        let template = Handlebars.compile(jQuery('#emails-list-template').html());
        this.listView.find('.collection').html(template(this.emails));
    }

    private renderTemplateHelp() {
        let template = Handlebars.compile(jQuery('#emails-help-template').html());

        let mocks = this.email.mocks.map(name => {
            let mock = this.templateHelp[name];
            mock.example = new Handlebars.SafeString(mock.example);
            return mock;
        });
        mocks.unshift(this.templateHelp.to);
        this.editorView.find('ul').html(template(mocks)).collapsible();
    }
}

window.app.page('page-emails', () => {
    return params => {
        if (!window.controllers.emails) {
            window.controllers.emails = new Emails(window.lifeVine);
        }
        if (!params) {
            window.controllers.emails.index();
        } else {
            window.controllers.emails.edit(params);
        }
    };
});

interface EmailsEmail {
    name: string;
    subject: string;
    message: string;
    mocks: string[];
}

interface EmailsOriginal {
    subject: string;
    subjectId: number;
    message: string;
    messageId: number;
}

interface EmailsMockData {
    admin?: any;
    booking?: any;
    client?: any;
    dependents?: any;
    location?: any;
    sitter?: any;
    sitters?: any[];
    to?: any;
}